import React, { Component } from 'react'
import store from "store"

import Layout from "../Components/reusable/Layout"
import Banner from "../Components/reusable/Banner"
export class Enquiry extends Component {

    state={
        products:[],
        formdata:{
            name:"",
            email:"",
            phone:"",
            address:"",
            message:"",
            country:"",
            company:""

        }
    }

    

    componentDidMount(){
        if (store.get("products")) {
            this.setState({
                ...this.state,
                products:store.get("products")
            })
        }
    }

handleChange =(e)=>{
this.setState({
        ...this.state,
        formdata:{
            ...this.state.formdata,
            [e.target.id]:e.target.value
        }
    })
}

handleSubmit = (e) =>{
    e.preventDefault()
    console.log("form data=>",this.state.formdata);
}

    render() {
        return (
            <Layout>
                <Banner title="Enquiry" />
               <form onSubmit={this.handleSubmit}>
            <div className="container py-5">
                <div className="row py-5">
               
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <input onChange={this.handleChange} value={this.state.formdata.name} required type="text" className="form-control" name="name" id="name" placeholder="Your Name" />
                        </div>
                    </div>
                       
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <input onChange={this.handleChange} value={this.state.formdata.company} required type="text" className="form-control" id="company" name="company" placeholder="Company Name" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <input onChange={this.handleChange} value={this.state.formdata.email} required type="email" className="form-control" name="email" id="email" placeholder="Email" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <input onChange={this.handleChange} value={this.state.formdata.phone} required type="mob no." className="form-control" id="phone" name="phone" placeholder="Mobile No." />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <input onChange={this.handleChange} value={this.state.formdata.address} required type="text" className="form-control" id="address" name="address" placeholder="Address" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <input onChange={this.handleChange} value={this.state.formdata.country} required type="text" className="form-control" id="country" name="country" placeholder="Country" />
                        </div>
                    </div>
                    
                        <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="form-group">
                            <textarea onChange={this.handleChange} value={this.state.formdata.message}  className="form-control" id="message" placeholder="Message" name="message" rows="5"></textarea>
                        </div>
                    </div>
                    
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group send_message">
                            <input   type="submit" id="submit" className="btn btn-secondary btn-send" value="Send Message" />   
                        </div>
                    </div>
               
                </div>
            </div>

        </form>
            </Layout>
        )
    }
}

export default Enquiry
